import styled from 'styled-components';
import { media } from '@mf/common/utils/styles/index';

export const WhatsappButtonWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isAtFooter'].includes(prop),
})<{ isAtFooter: boolean }>`
  position: fixed;
  z-index: 900;
  bottom: ${({ isAtFooter }) => (isAtFooter ? '-100px' : 'var(--spacing-4x)')};
  right: var(--spacing-4x);
  opacity: ${({ isAtFooter }) => (isAtFooter ? 0 : 1)};
  transition:
    bottom 0.5s ease,
    opacity 0.5s ease;

  ${media.lessThan('mobile')} {
    right: var(--spacing-2x);
    bottom: var(--spacing-2x);
  }
`;
