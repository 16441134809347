'use client';

import {
  ButtonIconFloatType,
  FsButtonIconFloat,
} from '@printi/ds-offset-react-core';
import { useEffect, useState } from 'react';
import { VersionToggle } from '@/screens/main/HomeV2/components/VersionToggle';
import * as S from './styles';

export default function HomeFooterActions() {
  const [isAtFooter, setIsAtFooter] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const footer = document.querySelector('footer');

      if (footer) {
        const footerRect = footer.getBoundingClientRect();

        if (footerRect.top <= window.innerHeight) {
          setIsAtFooter(true);
        } else {
          setIsAtFooter(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <S.WhatsappButtonWrapper id="whatsapp-button" isAtFooter={isAtFooter}>
        <FsButtonIconFloat
          typeButton={ButtonIconFloatType.Brand}
          icon="WhatsappLine"
          onClick={() => {
            window.open(' https://api.whatsapp.com/send?phone=551141183816');
            window.dataLayer.push({
              event: 'genericEvent',
              event_name: 'click_whatsapp_float_icon',
            });
          }}
        />
      </S.WhatsappButtonWrapper>
      <VersionToggle />
    </>
  );
}
