'use client';

import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { ButtonSize, FsButton } from '@printi/ds-offset-react-core';
import styled from 'styled-components';

const ToggleContainer = styled.div`
  position: fixed;
  bottom: 0px;
  left: 60px;
  z-index: 1000;
  border-radius: 8px;
  padding: 8px;
`;

export function VersionToggle() {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  // Only show on homepage
  if (pathname !== '/') {
    return null;
  }

  // Check if toggle should be visible - ONLY show if explicitly enabled
  const showToggleVersion = searchParams.get('showToggleVersion') === 'true';

  // Return null if toggle is not explicitly enabled
  if (!showToggleVersion) {
    return null;
  }

  // Determine current version - only use version parameter
  const versionParam = searchParams.get('version');
  const isV2 = versionParam === '2';

  const toggleVersion = () => {
    const params = new URLSearchParams(searchParams.toString());

    if (isV2) {
      // Switch to V1 by removing version parameter
      params.delete('version');
    } else {
      // Switch to V2
      params.set('version', '2');
    }

    // Preserve showToggleVersion parameter
    params.set('showToggleVersion', 'true');

    router.push(`/?${params.toString()}`);
  };

  return (
    <ToggleContainer>
      <FsButton size={ButtonSize.SM} onClick={toggleVersion}>
        {isV2 ? 'Mudar para V1' : 'Mudar para V2'}
      </FsButton>
    </ToggleContainer>
  );
}
