'use client';

import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { usePathname, useSearchParams } from 'next/navigation';

export type HomeVersion = 'v1' | 'v2';

interface HomeVersionContextType {
  homeVersion: HomeVersion;
  isHomeV2: boolean;
  isHomePage: boolean;
}

const HomeVersionContext = createContext<HomeVersionContextType>({
  homeVersion: 'v1',
  isHomeV2: false,
  isHomePage: false,
});

export const useHomeVersionContext = () => useContext(HomeVersionContext);

export function HomeVersionProvider({
  children,
  initialVersion = 'v1',
}: {
  children: ReactNode;
  initialVersion?: HomeVersion;
}) {
  const [homeVersion, setHomeVersion] = useState<HomeVersion>(initialVersion);
  const searchParams = useSearchParams();
  const pathname = usePathname();

  // Check if we're on the homepage
  const isHomePage = pathname === '/' || pathname === '/home-v2';

  useEffect(() => {
    // Get version from URL parameter - only use version parameter
    const versionParam = searchParams?.get('version');

    // Determine if we should show V2
    const isV2 = versionParam === '2';

    // If we're on /home-v2 path, always show V2
    const forceV2 = pathname === '/home-v2';

    setHomeVersion(isV2 || forceV2 ? 'v2' : 'v1');
  }, [searchParams, pathname]);

  return (
    <HomeVersionContext.Provider
      value={{
        homeVersion,
        isHomeV2: homeVersion === 'v2',
        isHomePage,
      }}
    >
      {children}
    </HomeVersionContext.Provider>
  );
}
